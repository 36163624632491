import { useEffect } from 'react';
import { WorkspaceRoutes } from './user/pages/WorkspaceRoutes';
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { Toast } from '@framework/ui/elements/Toast';
import { LogoutPage } from 'LogoutPage';
import { AuthContextProvider } from '@framework/auth';
import { HomePage } from '@user/pages/HomePage';
import { GroupPage } from '@user/pages/GroupPage';
import { GroupConsolePage, WithGroupConsoleMenu } from '@user/pages/GroupConsole';
import './styles/tailwind.css';
import { LoginPage } from '@user/pages/LoginPage';
import { NotFoundPage } from '@user/pages/NotFoundPage';
import { ReloadRedirect } from 'ReloadRedirect';
import { BuildInfo } from '@config/build-info';
import { EulaFooter } from '@user/EulaFooter';
import { ActionLogEventParamsRootContextProvider, useActionLogSender } from '@framework/action-log';
import { GroupMemberInvitationPage } from '@user/pages/GroupMemberInvitationPage';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { LoginCallback } from '@user/Login';
import { SignUpPage } from '@user/pages/SignUpPage';
import { ErrorBoundary } from '@framework/ui';
import { PasswordResetPage } from '@user/pages/PasswordResetPage';
import { AccountConnectedPage } from '@user/pages/AccountConnectedPage';
import { ViewModelPageRouteContent } from '@user/pages/ViewModelPage';
import { UserMetadata } from '@user/UserMetadata';
import { WithSidebar } from '@user/Sidebar';
import { WithBookmark } from './group/Bookmark';
import {
    ReloadOnResume,
    WithReloadRequiredVersion,
    WithDebugMode,
    PersistActiveClientSession,
    WithMaintenancePage,
} from '@framework/utils';
import { FirebaseAnalyticsOperation } from '@framework/firebase/analytics';
import { CheckIp } from '@user/CheckIp';

const usePageViews = () => {
    const location = useLocation();

    // Firebase Analytics のイベント記録
    useEffect(() => {
        FirebaseAnalyticsOperation.logEvent('page_view', {
            page_title: window.document.title,
            page_location: window.document.location.href,
            page_path: location.pathname,
            search: location.search,
            hash: location.hash,
            app_version: String(BuildInfo.version),
        });
    }, [location]);
};

const useActionLogPageViews = () => {
    const location = useLocation();
    const logSender = useActionLogSender();

    // 行動イベントログの記録
    useEffect(() => {
        logSender('global:page_view', {
            locationPathname: location.pathname,
            locationSearch: location.search,
            locationHash: location.hash,
            url: window.location.href,
        });
    }, [location, logSender]);
};

/**
 * react-router-dom の BrowserRouter の内側のコンポーネント。
 */
const RouterContents: React.FC<unknown> = () => {
    // 内部で useLocation() を利用しており、 BrowserRouter のページ遷移を追跡する。
    usePageViews();
    useActionLogPageViews();

    return (
        <Switch>
            <Route path={UserPagePathBuilder.loginPage()}>
                <LoginPage />
            </Route>
            <Route path={UserPagePathBuilder.signUpPage()}>
                <SignUpPage />
            </Route>
            <Route path={UserPagePathBuilder.logoutPage()}>
                <LogoutPage />
            </Route>
            <Route path={UserPagePathBuilder.oauthCallbackPage()}>
                <LoginCallback />
            </Route>
            <Route path={UserPagePathBuilder.passwordResetPage()}>
                <PasswordResetPage />
            </Route>
            <Route path={UserPagePathBuilder.accountConnectedPage()}>
                <AccountConnectedPage />
            </Route>
            <Route path={UserPagePathBuilder.groupMemberInvitationPage()}>
                <GroupMemberInvitationPage />
            </Route>
            <Route exact path="/">
                <>
                    <HomePage />
                    <EulaFooter />
                </>
            </Route>
            {/* ビューモデルページの旧パスから新パスへのリダイレクト */}
            <Redirect
                from={UserPagePathBuilder.legacyViewModelPage(':workspaceID', ':viewModelId')}
                to={{ pathname: UserPagePathBuilder.viewModelPage(':viewModelId'), search: location.search }}
            />
            <Route path={[UserPagePathBuilder.groupConsolePage(':groupId')]}>
                <WithGroupConsoleMenu>
                    <Route path={UserPagePathBuilder.groupConsolePage(':groupId')}>
                        <GroupConsolePage />
                    </Route>
                </WithGroupConsoleMenu>
            </Route>
            <Route
                path={[
                    UserPagePathBuilder.groupPage(':groupId'),
                    UserPagePathBuilder.workspacePage(':workspaceId'),
                    UserPagePathBuilder.viewModelPage(':viewModelId'),
                ]}
            >
                <WithBookmark>
                    <WithSidebar>
                        <Switch>
                            <Route path={UserPagePathBuilder.groupPage(':groupId')}>
                                <GroupPage />
                            </Route>
                            <Route path={UserPagePathBuilder.workspacePage(':workspaceId')}>
                                <WorkspaceRoutes />
                            </Route>
                            <Route path={UserPagePathBuilder.viewModelPage(':viewModelId')}>
                                <ViewModelPageRouteContent />
                            </Route>
                        </Switch>
                    </WithSidebar>
                </WithBookmark>
            </Route>
            {/* React 管理外のパスに対して内部リダイレクトしたときに、適切にページ全体をリロードさせる */}
            <Route path={['/api/', '/admin', '/static']}>
                <ReloadRedirect />
            </Route>
            <Route path="*">
                <NotFoundPage />
            </Route>
        </Switch>
    );
};

export const App: React.FC<unknown> = () => {
    return (
        <ErrorBoundary>
            {/* react-router-dom の BrowserRouter によるルーティング */}
            <Router>
                <WithDebugMode>
                    <AuthContextProvider>
                        {(currentUserId) => (
                            <>
                                {/* ActionLogEventParamsRootContextProvider は、ログイン中ユーザ情報を利用するため、 */}
                                {/* AuthContextProvider の内側に入れること */}
                                <ActionLogEventParamsRootContextProvider>
                                    <WithMaintenancePage>
                                        <WithReloadRequiredVersion>
                                            {/* 各ページのルーティング内容 */}
                                            {/* ログイン中のユーザIDが変化したときには、各ページの状態(state)をリセットする */}
                                            {/* https://beta.reactjs.org/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes */}
                                            <RouterContents key={currentUserId} />
                                        </WithReloadRequiredVersion>
                                    </WithMaintenancePage>

                                    {/* このアプリケーションのタブがスリープから復帰したときにページの再読み込みを行う */}
                                    <ReloadOnResume />
                                </ActionLogEventParamsRootContextProvider>

                                {/* ログイン中ユーザの情報を TrackJS, Firebase Analytics に設定します */}
                                <UserMetadata />
                                <PersistActiveClientSession />
                                <CheckIp />
                            </>
                        )}
                    </AuthContextProvider>
                </WithDebugMode>
            </Router>
            <Toast />
        </ErrorBoundary>
    );
};
